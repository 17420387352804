import App from './App.vue'
import router from './router'
import { antComponents } from './antd'
import { CommonComponents } from './use-common-components'
import 'virtual:svg-icons-register'
import store, { storeKey } from './store'
import { createInstance } from '/@/root'
import { useDirectives } from './directives'
import { createFromIconfontCN } from '@ant-design/icons-vue'
import '/@/styles/index.scss'
import 'ant-design-vue/dist/antd.less'
import * as echarts from "echarts"; 
const app = createInstance(App)
const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4123035_17iywx1cj2r.js',
})

app.config.globalProperties.$echarts = echarts; 
app.use(store, storeKey)
app.use(router)
app.component('IconFont', IconFont)
app.use(CommonComponents)
app.use(antComponents)
app.use(useDirectives)
app.mount('#demo-app')
