import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { ERouterName } from "/@/types/index";
import CreatePlan from "/@/components/task/CreatePlan.vue";
import WaylinePanel from "/@/pages/page-web/projects/wayline.vue";
import DockPanel from "/@/pages/page-web/projects/dock.vue";
import LiveAgora from "/@/components/livestream-agora.vue";
import LiveOthers from "/@/components/livestream-others.vue";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
//全局进度条的配置
NProgress.configure({
  easing: "ease", // 动画方式
  speed: 300, // 递增进度条的速度
  showSpinner: false, // 进度环显示隐藏
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 更改启动时使用的最小百分比
  parent: "body", //指定进度条的父容器
});
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: { name: "admin-login" },
    component: () => import("/@/pages/page-admin/layout/index.vue"),
    //光伏后台管理
    children: [
      {
        path: "/admin-home",
        name: "admin-home",
        meta: {
          title: "首页",
        },
        component: () => import("/@/pages/page-admin/home/index.vue"),
      },
      {
        path: "/power-station",
        name: "power-station",
        meta: {
          title: "电站管理",
        },
        component: () => import("/@/pages/page-admin/assets/power-station.vue"),
      },
      {
        path: "/inspection-device",
        name: "inspection-device",
        meta: {
          title: "巡检设备",
        },
        component: () =>
          import("/@/pages/page-admin/assets/inspection-device.vue"),
      },
      {
        path: "/backend",
        name: "backend",
        meta: {
          title: "后台系统",
        },
        component: () => import("/@/pages/page-admin/placehold/index.vue"),
      },
      {
        path: "/user",
        name: "user",
        meta: {
          title: "用户管理",
        },
        component: () => import("/@/pages/page-admin/placehold/index.vue"),
      },
      {
        path: "/production-reports",
        name: "production-reports",
        meta: {
          title: "生产报表",
        },
        component: () => import("/@/pages/page-admin/placehold/index.vue"),
      },
      {
        path: "/power-station-report",
        name: "power-station-report",
        meta: {
          title: "电站报表",
        },
        component: () =>
          import("/@/pages/page-admin/statistics/power-station-report.vue"),
      },
      {
        path: "/meteorology",
        name: "meteorology",
        meta: {
          title: "气象监测",
        },
        component: () => import("/@/pages/page-admin/placehold/index.vue"),
      },
      {
        path: "/monitor",
        name: "monitor",
        meta: {
          title: "监控摄像",
        },
        component: () => import("/@/pages/page-admin/placehold/index.vue"),
      },
      {
        path: "/car-personnel",
        name: "car-personnel",
        meta: {
          title: "车辆人员",
        },
        component: () => import("/@/pages/page-admin/placehold/index.vue"),
      },
      {
        path: "/real-time-monitoring",
        name: "real-time-monitoring",
        meta: {
          title: "实时监测",
        },
        component: () => import("/@/pages/page-admin/placehold/index.vue"),
      },
      {
        path: "/smart-diagnosis",
        name: "smart-diagnosis",
        meta: {
          title: "智能诊断",
        },
        component: () => import("/@/pages/page-admin/placehold/index.vue"),
      },
      {
        path: "/ops-manager",
        name: "ops-manager",
        meta: {
          title: "运维管理",
        },
        component: () => import("/@/pages/page-admin/placehold/index.vue"),
      },
      {
        path: "/database",
        name: "database",
        meta: {
          title: "数据仓库",
        },
        component: () => import("/@/pages/page-admin/placehold/index.vue"),
      },
      {
        path: "/realtime-data",
        name: "realtime-data",
        meta: {
          title: "实时数据",
        },
        component: () => import("/@/pages/page-admin/placehold/index.vue"),
      },
      {
        path: "/pv-live",
        name: "pv-live",
        meta: {
          title: "巡检直播",
        },
        component: () => import("/@/pages/page-admin/realtime/pv-live.vue"),
      },
      {
        path: "/auto-inspect",
        name: "auto-inspect",
        meta: {
          title: "自主值守",
        },
        component: () =>
          import("/@/pages/page-admin/realtime/auto-inspect.vue"),
      },
    ],
  },
  {
    path: "/inspect-home",
    name: "inspect-home",
    component: () => import("/@/pages/page-inspect/home.vue"),
  },
  {
    path: "/inspect-sub",
    name: "inspect-sub",
    component: () => import("/@/pages/page-inspect/sub.vue"),
  },
  {
    path: "/inspect-report",
    name: "inspect-report",
    component: () => import("/@/pages/page-inspect/report.vue"),
  },
  {
    path: "/defective-list",
    name: "defective-list",
    component: () => import("/@/pages/page-inspect/defective-list.vue"),
  },
  //光伏登录
  {
    path: "/admin-login",
    name: "admin-login",
    meta: {
      title: "登录",
    },
    component: () => import("/@/pages/page-admin/login/index.vue"),
  },
  //注册
  {
    path: "/register",
    name: "register",
    meta: {
      title: "注册",
    },
    component: () => import("/@/pages/page-admin/register/index.vue"),
  },
  //chongzhi
  {
    path: "/reset-password",
    name: "reset-password",
    meta: {
      title: "重置密码",
    },
    component: () => import("/@/pages/page-admin/reset-password/index.vue"),
  },
  // // 首页
  {
    path: "/" + ERouterName.PROJECT,
    name: ERouterName.PROJECT,
    component: () => import("/@/pages/page-web/index.vue"),
  },
  // // members, devices
  {
    path: "/" + ERouterName.HOME,
    name: ERouterName.HOME,
    component: () => import("/@/pages/page-web/home.vue"),
    children: [
      {
        path: "/" + ERouterName.MEMBERS,
        name: ERouterName.MEMBERS,
        component: () => import("/@/pages/page-web/projects/members.vue"),
      },
      {
        path: "/" + ERouterName.DEVICES,
        name: ERouterName.DEVICES,
        component: () => import("/@/pages/page-web/projects/devices.vue"),
      },
      {
        path: "/" + ERouterName.FIRMWARES,
        name: ERouterName.FIRMWARES,
        component: () => import("../pages/page-web/projects/Firmwares.vue"),
      },
    ],
  },
  // // workspace
  {
    path: "/" + ERouterName.WORKSPACE,
    name: ERouterName.WORKSPACE,
    component: () => import("/@/pages/page-web/projects/workspace.vue"),
    redirect: "/" + ERouterName.TSA,
    children: [
      {
        path: "/" + ERouterName.TSA,
        component: () => import("/@/pages/page-web/projects/tsa.vue"),
      },
      {
        path: "/" + ERouterName.LIVESTREAM,
        name: ERouterName.LIVESTREAM,
        component: () => import("/@/pages/page-web/projects/livestream.vue"),
        children: [
          {
            path: ERouterName.LIVING,
            name: ERouterName.LIVING,
            components: {
              LiveAgora,
              LiveOthers,
            },
          },
        ],
      },
      {
        path: "/" + ERouterName.LAYER,
        name: ERouterName.LAYER,
        component: () => import("/@/pages/page-web/projects/layer.vue"),
      },
      {
        path: "/" + ERouterName.MEDIA,
        name: ERouterName.MEDIA,
        component: () => import("/@/pages/page-web/projects/media.vue"),
      },
      {
        path: "/" + ERouterName.WAYLINE,
        name: ERouterName.WAYLINE,
        component: () => import("/@/pages/page-web/projects/wayline.vue"),
      },
      {
        path: "/" + ERouterName.TASK,
        name: ERouterName.TASK,
        component: () => import("/@/pages/page-web/projects/task.vue"),
        children: [
          {
            path: ERouterName.CREATE_PLAN,
            name: ERouterName.CREATE_PLAN,
            component: CreatePlan,
            children: [
              {
                path: ERouterName.SELECT_PLAN,
                name: ERouterName.SELECT_PLAN,
                components: {
                  WaylinePanel,
                  DockPanel,
                },
              },
            ],
          },
        ],
      },
      {
        path: "/" + ERouterName.FLIGHT_AREA,
        name: ERouterName.FLIGHT_AREA,
        component: () => import("/@/pages/page-web/projects/flight-area.vue"),
      },
    ],
  },
  // pilot
  {
    path: "/" + ERouterName.PILOT,
    name: ERouterName.PILOT,
    component: () => import("/@/pages/page-pilot/pilot-index.vue"),
  },
  {
    path: "/" + ERouterName.PILOT_HOME,
    component: () => import("/@/pages/page-pilot/pilot-home.vue"),
  },
  {
    path: "/" + ERouterName.PILOT_MEDIA,
    component: () => import("/@/pages/page-pilot/pilot-media.vue"),
  },
  {
    path: "/" + ERouterName.PILOT_LIVESHARE,
    component: () => import("/@/pages/page-pilot/pilot-liveshare.vue"),
  },
  {
    path: "/" + ERouterName.PILOT_BIND,
    component: () => import("/@/pages/page-pilot/pilot-bind.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

/** 此处只添加路由进度条动画 */
router.beforeEach((to, from, next) => {
  NProgress.start();
  next();
});
router.afterEach(() => {
  NProgress.done();
});

export default router;
