import { StoreOptions } from "vuex";
const adminStore: StoreOptions<any> = {
  state: () => {
    return {
      layer: 1, //1矢量2影像
      osmBuildings: false,
      //报表
      reportInfo: {
        type: 1, //1显示所有电站2显示电站下所有楼栋3显示单个缺陷
        info: {},
      },
      //直播
      liveInfo: {
        device_sn: "",
        status: false,
      },
    };
  },
  mutations: {
    SET_REPORT_INFO(state, payload) {
      state.reportInfo = payload;
    },
    SET_LIVE_INFO(state, payload) {
      state.liveInfo = payload;
    },
    SET_LAYER(state, payload) {
      state.layer = payload;
    },
    SET_OSM(state, payload) {
      state.osmBuildings = payload;
    },
  },
  actions: {},
  getters: {},
};
export default adminStore;
